
body {
    --token-f512b2c0-ccff-4c1c-81ef-5b1a68ff0d48: ;
    --token-a7f7ae5f-a181-442b-8823-be0ca1b170fe: rgb(255, 255, 255);
    --token-636a3fe4-9f0f-4ae1-a43c-60a743a1245b: rgb(239, 245, 245);
    --token-e29b414e-778b-4330-a92f-715c64d82d8a: rgb(8, 22, 23);
    --token-d8aebec7-127a-45a9-b7a0-7e4b0ca8d5d3: ;
    --token-6d05c7e7-3db1-411c-8b7a-d2c7f93bf6a9: rgb(18, 18, 18);
    --token-0c8fd507-faea-491a-8caf-ad6cd40c959b: rgb(4, 2, 10);
    --token-310329de-68fc-4faf-b7fe-59c281f49fcd: rgb(56, 113, 91);
    --token-c030e036-f7b8-4fe7-a323-429bf2e2e037: rgb(9, 100, 103);
    --token-58db98a9-d75a-4644-908a-273a4a7e26ed: rgb(117, 73, 242);
    --token-c7fbd80c-1445-45a3-8f35-a6e52a892fa1: rgb(222, 83, 247);
    --token-18a88464-7663-46f8-be95-8e136a86211c: rgb(247, 247, 247);
    --token-c19b8f04-0116-4ebc-bd47-5dffbf52dc9e: #EF8B55;
    --token-72d15cbe-ecef-4b63-b107-d52645ec5d3e: rgb(199, 246, 209);
    --token-8f446c89-8723-4193-89b9-b5d64ef1a9fe: rgb(142, 237, 162);
    --token-cd9c00a8-ffc6-44af-8782-6163fb5de27b: rgb(114, 232, 139);
    --token-978f2fef-69e3-4876-ac4a-795775188fea: #b0e5c7;
    --token-d856f864-f92e-4ab1-b5a8-0765e4f9ecea: rgb(139, 181, 157);
    --token-7eddaf8c-b884-474d-afa6-e0577b80a5c7: rgb(102, 133, 115);
    --token-80c9e3f1-fecf-421c-b744-80731f5ad0c0: rgb(209, 121, 119);
    --token-c3fb7400-b3c1-4ecb-b931-b0d92e2c1424: rgb(137, 247, 161);
    --token-c0073c07-074c-4756-bc13-552e9699190f: rgb(150, 188, 253);
    --token-442963ec-49b1-4419-9aa6-99381d2f3f7b: rgb(99, 137, 202);
    --token-af0f4629-7aab-46ff-9606-390363ded9f7: rgb(68, 84, 195);
    --token-2798c9c3-c401-4ea2-9db6-e0c44caa44a8: rgb(68, 84, 195);
    --token-470a04b7-94a3-4bb6-a9fa-2b019351c0b8: rgb(38, 45, 109);
    --token-8cdaf633-6f3c-4ee0-903d-cd395705179b: rgb(3, 4, 10);
}
.inner_card_wrapper {
    place-content: flex-start;
    align-items: flex-start;
    background-color: var(--token-a7f7ae5f-a181-442b-8823-be0ca1b170fe, #ffffff);
    border-radius: 10px;
    display: flex;
    flex: 0 0 auto;
    flex-flow: column;
    gap: 4px;
    height: min-content;
    overflow: visible;
    padding: 32px 24px 44px 24px;
    position: relative;
    width:100%
}

.sub_heading_wrapper {
    margin-top: 0;
    display: flex;
    flex-direction: column;
}

.title_two {
    font-size: 20px;
    margin: 4px 0;
    font-weight: 700;
    line-height: 1.2em;
}
.sub_heading {
    font-size: 18px;
    margin: 4px 0;
    font-weight: 400;
    line-height: 1.2em;
}

.input_header {
    font-size: 14px;
    /*margin: 4px 0;*/
    font-weight: 600;
    line-height: 1.2em;
}

.small_input_header {
    font-size: 14px;
    color:#4454c3;
    font-weight: 400;
    margin: 8px 0;
    width:650px;
    line-height: 1.2em;

}

.input_wrapper {
    --border-bottom-width: 1px;
    --border-color: #d9d9d9;
    --border-left-width: 1px;
    --border-right-width: 1px;
    --border-style: solid;
    --border-top-width: 1px;
    place-content: center flex-start;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    gap: 20px;
    overflow: visible;
    padding: 0px 0px 0px 12px;
    position: relative;

}

.copy_button{
    place-content: center;
    align-items: center;
    background-color: var(--token-af0f4629-7aab-46ff-9606-390363ded9f7, #4454c3);
    border-radius: 4px;
    box-shadow: rgba(5, 145, 255, 0.1) 0px 2px 0px 0px;
    display: flex;
    flex: 0 0 auto;
    flex-flow: row;
    gap: 10px;
    overflow: visible;
    padding: 6px 32px;
    position: relative;
    width: min-content;
    height:45px

}
